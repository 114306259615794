// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// Jquery
import jquery from "jquery";

window.$ = window.jquery = jquery;
window.jQuery = window.jquery = jquery;

// Common Lib
import "bootstrap";
import "@fortawesome/fontawesome-free/js/all";

global.toastr = require("toastr");
global.bootbox = require("bootbox");
global.moment = require("moment");
require("inputmask");
require("select2");
require("bootstrap-datepicker");
require("bootstrap-timepicker");
require("bootstrap-slider");


//Theme
require("admin-lte");
require.context("admin-lte/dist/img", true);

// AngularJs
global.angular = require("angular");
require("angular-route");
require("packs/base/init");
require("packs/base/angular_app");
require("packs/base/angular_utils");
require("packs/lib/combodate");

// AngularJs Controllers
global.app = angular.module("app");
require("packs/pages/sign_up_ctrl");
require("packs/pages/credit_card_ctrl");
require("packs/pages/booking_ctrl");
require("packs/pages/send_sms_email_ctrl");
require("packs/pages/make_payment_ctrl");
require("packs/pages/control_dashboard_ctrl");
require("packs/pages/send_invitations_ctrl");
require("packs/pages/send_user_redeem_ctrl");
require("packs/pages/report_ctrl");
require("packs/pages/combine_room_ctrl");
require("packs/pages/room_price_ctrl");
require("packs/pages/your_room_ctrl");
require("packs/pages/overview_ctrl");
require("packs/pages/change_room_occupancy_level_ctrl");
